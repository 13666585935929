<template>
    <div class="text-center">
        <h3 class="my-4">
            <strong v-text="$t('messages.ensureShipment')"></strong>
        </h3>
        <div class="row justify-content-center">
            <div class="col-12 co-md-8 col-xl-7 ">
                <p v-html="$tc('messages.ensureMessage',1,{ensure : fnFormatPrice(3000 * exchangesList[ orderInformation.currency], orderInformation.currency) })">
                </p>
            </div>
        </div>
        <hr class="w-50">
        <p v-if="orderInsurance">
            <strong v-text="$t('messages.insurancePrice') + ': ' "></strong>
            <span class="text-success" v-text=" fnFormatPrice( (typeof orderInsurance.totalPrice != undefined || orderInsurance.totalPrice != null ? orderInsurance.totalPrice : 0) * exchangesList[ orderInformation.currency], orderInformation.currency )  "></span>
        </p>
        <b class="f-w-600"></b>
        <div class="row align-items-center justify-content-center my-4">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <button class="btn btn-success w-100 mb-2 rounded-pill " @click=" $emit('ensureShipment') "  v-text="$t('messages.ensure')"> </button>
            </div>
            <div class="col-12"></div>
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 ">
                <button class="btn btn-light w-100 rounded-pill" @click=" $emit('skip') " v-text="$t('general.button.skip')"> </button>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex'
    export default {
        data() {
            return {

            }
        },
        computed: {
            ...mapState('order', ['exchangesList', 'orderInformation','orderInsurance']),
        },
        methods: {
            ...mapActions('order', ['fnApiGetInsurance']),
        },
        mounted() {
            // this.fnApiGetInsurance();
        }

    }
</script>

<style>

</style>