<template>
  <div class="card shadow-none  ">
    <div class="card-header d-flex align-items-center justify-content-between">
      <h6>
        <span v-text="$t('dashboard.order.internationalShipment')"> </span>
        <small>
          <strong> - </strong>
          <strong class="text-warning" v-text=" $t('dashboard.order.shipping.warning') "></strong>
        </small>
      </h6>
      <button class="btn btn-sm btn-info" @click="fnValidateDataBOL()" :disabled="loadingBOL">
        <!-- <i class="fa fa-print mr-1"></i> -->
        <span v-text=" $t('general.button.BOL') "></span>
      </button>
    </div>
    <div class="card-body" v-if="!shippingInformation.own_shipping">
      <p class="">
        <span v-html="$t('components.trackingNumber.bolMessage')"> </span>
        <strong v-html="$t('components.trackingNumber.bolWarning')"></strong>
      </p>
      <p class="mb-3"></p>
      <hr>
      <v-observer ref="bolForm" tag="form" @submit.prevent="fnValidateDataBOL()">
        <div class="row mb-1 align-items-center" v-for=" (item,index) in productsList " :key=" index ">
          <div class="col-auto">
            <img v-image=" item.image_url " alt="" class="img-cover-30 border bg-white">
          </div>
          <div class="col">
            <v-validation rules="required" v-slot="{ errors }" :name=" 'Init' + item.id ">
              <input type="text" maxlength="250" v-model="item.name" class="form-control form-control-sm"
                :class=" { 'is-invalid':errors.length > 0 } ">
            </v-validation>
          </div>
        </div>
      </v-observer>
    </div>
    <div class="card-body" v-else>
      <p>
        <span v-text="$t('dashboard.order.externalLabel') + ' '"></span>
        <span v-html="$t('dashboard.order.externalLabelMessageBOL')"></span>
      </p>
    </div>
  </div>
</template>

<script>
  import printJS from 'print-js';
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";
  export default {
    data() {
      return {
        productsList: [],
        loadingBOL: false,
      }
    },
    computed: {
      ...mapState('order', ['orderInformation', 'shippingInformation']),
      ...mapState(['device']),
    },
    methods: {
      async fnValidateDataBOL() {
        if (await this.$refs['bolForm'].validate()) {
          this.fnUpdateOrderBOL();
        }
      },
      async fnUpdateOrderBOL() {
        this.loadingBOL = true;
        let tempItemsList = [];
        for (let item of this.productsList) {
          tempItemsList.push({
            id: item.item_id,
            title: item.name
          });
        }
        await axios.post(
          `orders/${this.orderInformation.id}/shippings/${this.orderInformation.shipping_items[0].id}/billoflading`,
          tempItemsList).then((response) => {

          if (this.device) {
            DownloadFile.postMessage(JSON.stringify({
              url: this.shippingInformation.bill_of_landing,
              fileName: this.orderInformation.number + '-Bill of landing.pdf',
            }));
            // PDFLink.postMessage(JSON.stringify({
            //   title: this.$t('dashboard.order.bol'),
            //   url: this.shippingInformation.bill_of_landing,
            // }));
          } else {
            if (process.env.NODE_ENV == 'development') {
              window.open(this.shippingInformation.bill_of_landing);
            } else {
              printJS(this.shippingInformation.bill_of_landing);
            }
          }

        }).catch(error => {});
        this.loadingBOL = false;
      }
    },
    mounted() {
      this.productsList = this.$lodash.cloneDeep(this.orderInformation.items);
    }
  }
</script>

<style>

</style>