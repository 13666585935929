<template>
  <div class="row h-100" id="OrderInformation">

    <div class="col-12" v-if=" !loading.data ">
      <div v-if="orderCharge.id != null">

        <Banner bannerType="dark" :title="$t('dashboard.order.bannerCharge.title')" :loading="loading.charge"
          :message="$t('dashboard.order.bannerCharge.message')" :actionLabel="$t('general.button.appeal')"
          @action="fnApiUpdateCharge(orderCharge.id ) " v-if="orderCharge.status == 'pending' " />

        <Banner bannerType="dark" :title="$t('dashboard.order.bannerCharge.title')"
          :message="$t('dashboard.order.bannerCharge.messageReview')" v-if="orderCharge.status == 'review'" />

        <banner bannerType="dark" :title="$t('dashboard.order.bannerCharge.title')" v-if="orderCharge.status =='won'">
          <template v-slot:message-secction>
            <p>
              <span v-text="$t('dashboard.order.appeal') + ': ' "></span>
              <strong class="text-success" v-text="$t('dashboard.order.appealStatus.won') "></strong>
            </p>
          </template>
        </banner>

        <banner bannerType="dark" :title="$t('dashboard.order.bannerCharge.title')" v-if="orderCharge.status =='lost'">
          <template v-slot:message-secction>
            <p>
              <span v-text="$t('dashboard.order.appeal') + ': ' "></span>
              <strong class="text-danger" v-text="$t('dashboard.order.appealStatus.lost') "></strong>
              <br>
              <span v-text="$t('dashboard.order.appealMessageLost') + ' '"> </span>
              <strong class="text-danger"
                v-text="fnFormatPrice( ( orderCharge.charge_amount ) , orderInformation.currency )"> </strong>
            </p>
          </template>
        </banner>

      </div>
      <div v-else>
        <!-- <Banner bannerType="warning" :title="$t('dashboard.order.bannerPayment.title')"
            v-if="orderInformation.status == 'pending'" :message="$t('dashboard.order.bannerPayment.message')" />
          <Banner :title=" $t('dashboard.order.bannerLabel.title')" :message="$t('dashboard.order.bannerLabel.message')"
            @action="setModalTracking( !showtrackingNumber)" bannerType="success" actionIcon="receipt" titleIcon="star"
            :actionLabel="$t('general.createLabel')"
            v-if="orderInformation.status == 'paid' && ((orderInformation.shipment_status == 'pending')) && (orderInformation.order_status != 'cancelled') " /> -->
      </div>

      <div class="card card-ecart " :class=" ordersPage ? 'shadow-none m-0 border-0' : '' ">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col-12 col-lg-9 col-xl-9 d-flex flex-column justify-content-between">
              <h5 class="text-center text-lg-left mb-2 mb-lg-0 text-white" v-text=" $t('dashboard.order.title') "> </h5>
              <p class="m-0 text-success f-w-500 d-none d-lg-block"
                v-if="orderInformation.status == 'paid' && ((orderInformation.shipment_status == 'pending')) && (orderInformation.order_status != 'cancelled') ">
                <span v-text="$t('dashboard.order.bannerLabel.message')"></span>
              </p>
              <p class="m-0" v-if="orderInformation.status == 'pending'">
                <span v-text="$t('dashboard.order.bannerPayment.message')"></span>
              </p>
            </div>
            <div v-if=" newTrackingType == null && !internationalMessage && !ensureOrderMessage"
              class="col-12 col-lg-3 col-xl-3 ">
              <button class="btn btn-lg btn-success w-100 f-w-600 rounded-pill" @click="fnValidateAddress()"
                :disabled="loading.createTracking"
                v-if="orderInformation.status == 'paid' && ((orderInformation.shipment_status == 'pending')) && (orderInformation.order_status != 'cancelled' && orderInformation.order_status != 'in_review' ) ">
                <span v-if=" !loading.createTracking ">
                  <!-- <i class="fa fa-receipt mr-1"></i> -->
                  <span v-text="$t('general.createLabel')"></span>
                </span>
                <i class="fa fa-spinner fa-spin " v-else></i>
              </button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row justify-content-between">
            <div class="col-auto">
              <p class="m-0">
                <strong v-text=" $t('general.form.number') + ': ' "></strong>
                <span class="text-uppercase f-w-600" v-text=" '#' +  orderInformation.number + '-' +  orderInformation.store_id.slice(-3) "> </span>
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 text-truncate">
                <span v-text=" $t('tables.order') + ': ' "></span>
                <span v-text=" fnST('general.' , orderInformation.order_status ) "
                  :class=" orderInformation.order_status == 'cancelled' ? 'text-danger' : ''  "> </span>
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 text-truncate">
                <span v-text=" $t('tables.payment') + ': ' "></span>
                <span v-text=" fnST('general.' , orderInformation.status) "> </span> </p>
            </div>
            <div class="col-auto">
              <p class="m-0">
                <span v-text=" $t('general.form.date') + ': ' "></span>
                <span v-text=" fnDate( orderInformation.created_at,  CONST.DATE_FORMATS.DD_MM_YYYY )  "></span>
              </p>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <OrderInsurance class="animated fadeIn" @ensureShipment=" fnApplyEnsureShipment() "
            @skip=" fnSkipEnsureShipment() " v-if="ensureOrderMessage" />
          <div v-show="!loading.createTracking">
            <div v-show="!internationalMessage && !ensureOrderMessage">
              <div class="row"
                v-if="shippingInformation.tracking_number == null && ( newTrackingType != 'own') && (orderInformation.order_status != 'cancelled') ">
                <div class="col-12 col-xl-6">
                  <div class="card shadow-none ">
                    <div class="card-header">
                      <div class="d-flex justify-content-between">
                        <h6 v-text="$t('dashboard.order.shipping.origin')"></h6>
                        <button class="btn btn-xs btn-link p-0" v-if="addressesList.length > 0"
                          v-text=" !changeAddress ? $t('general.button.changeOrigin') : $t('general.button.hide') "
                          @click="changeAddress = !changeAddress ">
                        </button>
                      </div>

                      <v-observer tag="form" class=" animated fadeIn mt-2 form-rounded-inputs" ref="formAddress"
                        v-show="changeAddress" @submit.prevent="fnValidateAddress()">
                        <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.address') ">
                          <div class="form-group mb-0">
                            <!-- <label v-text="$t('dashboard.order.shipping.originMessage')"> </label> -->
                            <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                              <select class="custom-select" @change="fnChangeAddress()"
                                :class=" { 'is-invalid ':errors.length > 0 } " v-model="tempAddressId">
                                <option :value="null" v-text=" $t('general.form.select') ">
                                </option>
                                <option :value="address.id" v-for=" (address,index) in addressesList " :key="index"
                                  v-text="address.address_name">
                                </option>
                              </select>
                              <div class="input-group-append">
                                <label class="input-group-text">
                                  <i class="fa fa-warehouse "></i>
                                </label>
                              </div>
                            </div>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </v-validation>
                        <input type="submit" hidden>
                      </v-observer>

                    </div>
                    <div class="card-body">
                      <div class="d-flex flex-column justify-content-between order-address-body" v-if="tempAddressId">
                        <div class="row">
                          <div class="col-12">
                            <p class="m-0">
                              <span
                                v-text=" $t('tables.name') + ': ' + tempAddress.first_name + ' ' + tempAddress.last_name "></span>
                            </p>
                          </div>
                          <div class="col-12">
                            <p class="m-0"> <span v-text=" $t('tables.phone') + ': ' + tempAddress.phone "></span>
                            </p>
                          </div>
                          <div class="col-12">
                            <p class="m-0">
                              <span v-text=" $t('tables.address') + ': ' "></span>
                              <span v-text="fnFormatAddress( tempAddress )"></span>
                            </p>
                          </div>
                        </div>
                        <p class="text-right">
                          <button class="btn btn-link btn-xs p-0 " :class=" newAddresses.origin ? 'text-muted':''  "
                            v-text=" newAddresses.origin ? $t('general.button.canelEdition') : $t('general.button.editAddress')  "
                            @click=" newAddresses.origin = !newAddresses.origin "></button>
                        </p>
                      </div>
                      <div class="d-flex flex-column justify-content-center align-items-center order-address-body "
                        v-else>
                        <p class="text-center">
                          <i class="fa fa-exclamation-triangle text-warning mr-1 "></i>
                          <span v-text="$t('dashboard.order.shipping.selectAddressMessage') + ' ' "></span>
                          <router-link :to=" '/dashboard/stores/' + storeInformation.id " class="text-lowercase"
                            v-text="$t('general.button.here')"></router-link>.
                        </p>
                      </div>
                      <div v-if="newAddresses.origin">
                        <hr>
                        <AddressForm :addressInformation="tempAddress" :disabledList="disabledFields.origin"
                          ref="originAddress" />
                      </div>


                    </div>
                  </div>
                </div>

                <div class="col-12 col-xl-6">
                  <div class="card shadow-none ">
                    <div class="card-header">
                      <h6 v-text="$t('dashboard.order.shipping.destination')">
                      </h6>
                    </div>
                    <div class="card-body ">

                      <div class=" d-flex flex-column justify-content-between order-address-body">
                        <div class="row" v-if="orderInformation.shipping_address">
                          <div class="col-12">
                            <p class="m-0"> <span
                                v-text=" $t('tables.name') + ': ' + orderInformation.shipping_address.first_name + ' ' + orderInformation.shipping_address.last_name "></span>
                            </p>
                          </div>
                          <div class="col-12">
                            <p class="m-0"> <span
                                v-text=" $t('tables.phone') + ': ' + orderInformation.shipping_address.phone "></span>
                            </p>
                          </div>
                          <div class="col-12">
                            <p class="m-0">
                              <span v-text=" $t('tables.address') + ': ' "></span>
                              <span v-text="fnFormatAddress( orderInformation.shipping_address )"></span>
                            </p>
                          </div>
                        </div>
                        <!-- <p class="text-right">
                          <button class="btn btn-link btn-xs p-0"
                            :class=" newAddresses.shippingAddress ? 'text-muted':''  "
                            v-text=" newAddresses.shippingAddress ? $t('general.button.canelEdition') : $t('general.button.editAddress')  "
                            @click=" newAddresses.shippingAddress = !newAddresses.shippingAddress "></button>
                        </p> -->
                      </div>
                      <div v-if="newAddresses.shippingAddress">
                        <hr>
                        <AddressForm :disabledList="disabledFields.shippingAddress"
                          :addressInformation="orderInformation.shipping_address" ref="shippingAddress" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row align-items-start">
                <div class="col-12" v-if="shippingInformation.tracking_number">
                  <OrderBolInformation v-if="orderInformation.international " />
                </div>

                <div class="col-12 col-xl-6">
                  <OrderDetails />
                </div>
                <div class="col-12 col-xl-6">
                  <OrderShipmentInformation />
                </div>

                <div class="col-12"
                  v-if="shippingInformation.tracking_number || (orderInformation.order_status == 'cancelled') ">
                  <OrderShipmetnAddresses
                    v-if="shippingInformation.tracking_number || ( newTrackingType == 'own') || (orderInformation.order_status == 'cancelled') " />
                </div>

              </div>
            </div>
            <OrderInternationalInformation @update-shiping=" fnEnsureShipment() " v-show="internationalMessage" />


          </div>
          <div class="card" v-show="loading.createTracking">
            <div class="card-body">
              <no-data :dataMessage="$t('noDataMessages.errorDefault')" :isLoading="loading.createTracking"
                :showBtn="false">
              </no-data>
            </div>
          </div>

          <OrderDelete
            v-if=" (orderInformation.order_status != 'cancelled') && (orderInformation.shipment_status != 'delivered') && !ensureOrderMessage " />


        </div>
      </div>

    </div>
    <div class="col-12">
      <div class="card" v-if="loading.data ">
        <div class="card-body">
          <no-data :dataMessage="$t('noDataMessages.errorDefault')" :isLoading="loading.data" :showBtn="false">
          </no-data>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import Banner from '@/components/OrdersBanner';
  import AddressForm from "@/components/AddressForm";
  import OrderInternationalInformation from './components/order-international-information';
  import OrderDetails from './components/order-details';
  import OrderShipmentInformation from './components/order-shipment-information';
  import OrderBolInformation from "./components/order-bol";
  import OrderShipmetnAddresses from "./components/order-shipment-addresses";
  import OrderDelete from './components/order-delete';
  import OrderInsurance from './components/order-insurance';

  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {
    props: {
      orderId: {
        required: true,
      },
      ordersPage: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      OrderDetails,
      OrderBolInformation,
      OrderShipmentInformation,
      OrderShipmetnAddresses,
      OrderInternationalInformation,
      OrderDelete,
      OrderInsurance,
      Banner,
      AddressForm,
    },
    data() {
      return ({
        internationalMessage: false,
        ensureOrderMessage: false,
        changeAddress: false,
        tempAddressId: null,
        newAddresses: {
          origin: false,
          shippingAddress: false,
        },
        tempAddresses: {
          insurance: null,
          origin_id: null,
          origin_address: {},
          shipping_address: {},
        },
        disabledFields: {
          origin: ['addressName'],
          shippingAddress: ['addressName', 'userName', 'userLastName', 'phone'],
        }
      });
    },
    watch: {
      addressesList() {
        this.fnDefaultAddress();
      },
    },
    computed: {
      ...mapState('order', ['modalCancelOrder', 'sendingData', 'orderInformation', 'trackingInformation',
        'addressesList', 'storeInformation', 'loading.data', 'showTraking', 'showtrackingNumber', 'modals', 'loading',
        'orderCharge', 'shippingInformation', 'newTrackingType', 'exchangesList','orderInsurance'
      ]),
    },
    methods: {
      ...mapActions('order', ['fnApiGetOrder', 'fnApiUpdateCharge', 'fnApiCreateShippingLabel', 'fnApiGetExchange',
        'fnApiGetInsurance'
      ]),
      ...mapMutations('order', ['setModalCancelOrder', 'resetState']),

      fnDefaultAddress() {
        this.addressesList.map(address => {
          if (address.default) {
            this.tempAddressId = address.id;
            this.fnChangeAddress();
          }
        });
      },
      fnChangeAddress() {
        this.tempAddress = {};
        this.newAddresses.origin = false;
        if (this.tempAddressId) {
          this.tempAddress = this.addressesList.find(address => address.id == this.tempAddressId);
        }
      },

      async fnValidateAddress() {
        if (await this.$refs['formAddress'].validate()) {
          let index = this.$lodash.findIndex(this.addressesList, ['id', this.tempAddressId]);
          let validateAddresses = await this.fnApiUpdateAddresses();

          if (validateAddresses) {
            if (this.addressesList[index].country.code == this.orderInformation.shipping_address.country.code) {
              this.fnEnsureShipment();
            } else {
              this.internationalMessage = true;
            }
          }

        }
      },

      async fnEnsureShipment() {
        this.loading.createTracking = true;
        this.internationalMessage = false;
        if (this.orderInformation.currency != 'MXN' || ((this.orderInformation.currency == 'MXN') && (this
            .orderInformation.totals.total > 3000))) {
          await this.fnApiGetExchange();
          if ((this.orderInformation.totals.total / this.exchangesList[this.orderInformation.currency]) > 3000) {
            await this.fnApiGetInsurance();
            this.ensureOrderMessage = true;
          } else {
            await this.fnApiCreateShippingLabel(this.tempAddresses);
          }
        } else {
           await this.fnApiCreateShippingLabel(this.tempAddresses);
        }
        this.loading.createTracking = false;
      },

      async fnApplyEnsureShipment() {
        this.tempAddresses.insurance = this.orderInsurance.totalPrice * this.exchangesList[this.orderInformation.currency];
        this.fnCreateShippingLabel();
      },

      async fnSkipEnsureShipment() {
        this.fnCreateShippingLabel();
      },
      
      async fnCreateShippingLabel(){
        this.ensureOrderMessage = false;
        this.loading.createTracking = true;
        await this.fnApiCreateShippingLabel(this.tempAddresses);
        this.loading.createTracking = false;
      },

      async fnApiUpdateAddresses() {

        this.tempAddresses = {
          origin_id: this.tempAddressId,
        };
        
        if (this.newAddresses.origin) {
          let originAddress = await this.$refs.originAddress.fnValidateData();
          if (originAddress == null) {
            return null;
          }
          originAddress = this.fnGlobalGetAddress(originAddress);
          delete originAddress.address_name;
          this.tempAddresses.origin_address = originAddress;
        } else {
          this.tempAddresses.origin_address = null;
        }

        if (this.newAddresses.shippingAddress) {
          let shippingAddress = await this.$refs.shippingAddress.fnValidateData();
          if (shippingAddress == null) {
            return null;
          }
          shippingAddress = this.fnGlobalGetAddress(shippingAddress);
          delete shippingAddress.address_name;
          this.tempAddresses.shipping_address = shippingAddress;
        } else {
          this.tempAddresses.shipping_address = null;
        }

        return true;
      },
    },
    mounted() {
      if (this.orderId != null) {
        this.fnApiGetOrder(this.orderId);
      }
    },
    beforeMount() {
      this.resetState();
    }
  }
</script>

<style>
  /* .order-address-header {
    min-height: 130px;
  } */
  .order-address-body {
    min-height: 130px;
  }
</style>