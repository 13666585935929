<template>
  <div>

    <div class="card shadow-none m-0 form-rounded-inputs" id="DeleteForm">
      <div class="" :class="modalCancelOrder ? 'card-header' : 'card-body' " v-if=" !modalCancelOrder ">
        <p class="text-right">
          <button class="btn btn-sm btn-link " @click="setModalCancelOrder(true)" 
          v-text=" $t('general.button.cancel') "> </button>
        </p>
      </div>
      <div class="card-header" v-if=" modalCancelOrder  ">
        <h6 v-text="$t('dashboard.order.cancelOrder')"></h6>
      </div>
      <div class="card-body" v-if=" modalCancelOrder  ">
        <v-observer tag="form" class="mb-4 animated fadeIn" ref="formReasonToCancel" @submit.prevent="fnValidateData()">
          <v-validation rules="required" v-slot="{ errors }" :name="'Reason'" v-show=" !cancelReason.message ">
            <table class="table ecart-table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th class="col" v-text="$t('dashboard.order.reason')"> </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (reason,index) in reasons " :key="index">
                  <td>
                    <input type="radio" :id=" index +'reason'" :value="reason.value" v-model="cancelReason.reason">
                  </td>
                  <td>
                    <label :for="index +'reason'" class="cursor-pointer m-0 w-100">
                      <span v-text=" reason.name "></span>
                      <br>
                      <span class="text-muted" v-text=" reason.description "> </span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class=" p-2 bg-danger " v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </v-validation>
          <v-validation rules="required|min:8|max:500" v-slot="{ errors }" :name=" $t('general.form.comments') "
            v-show=" !cancelReason.message ">
            <div class="form-group h-100">
              <label for="" v-text=" $t('general.form.comments') "> </label>
              <textarea cols="30" rows="5" maxlength="500" class="form-control " v-model="cancelReason.comments"
                :class=" { 'is-invalid':errors.length > 0 } "></textarea>
              <div class="invalid-feedback" v-if="errors.length > 0">
                {{ errors[0] }}
              </div>
            </div>
          </v-validation>

          <div class="card m-0 border border-danger " v-if="cancelReason.message">
            <div class="card-body">
              <p><strong v-text="$t('dashboard.order.cancelSection.title')"> </strong></p>
              <p v-text="$t('dashboard.order.cancelSection.message1')"> </p>
              <p>
                <span v-text="$t('dashboard.order.cancelSection.message2')" class="mr-1"></span>
                <strong class="text-danger"
                  v-text="fnFormatPrice( (orderInformation.totals.total * .10 ) , orderInformation.currency )">
                </strong>.
              </p>
              <v-validation :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
                :name="$t('general.button.confirmation')">
                <div class="form-group form-check m-0">
                  <input type="checkbox" class="form-check-input" id="cancelReason" v-model=" cancelReason.charge ">
                  <label class="form-check-label" for="cancelReason"
                    v-text="$t('dashboard.order.cancelSection.acceptmessage')">
                  </label>
                </div>
                <p class="bg-danger pl-1" v-if="errors.length > 0">
                  <small> {{ errors[0] }} </small>
                </p>
              </v-validation>
              <p>
                <small>
                  <span v-text="$t('dashboard.order.cancelSection.link')"></span>
                  <router-link target="_blank" to="/terms-&-conditions" v-text=" $t('signup.terms') "> </router-link>
                </small>
              </p>
            </div>
          </div>
          <input type="submit" hidden>

        </v-observer>
        <div class="d-flex justify-content-between w-100 ">
          <b-button variant="light" class="mr-2" @click="resetCancelOrder(true)"
            v-text=" $t('general.button.close') ">
          </b-button>
          <b-button variant="danger" class="" @click="fnValidateData() " :disabled="loading.cancelOrder">
            <span v-text=" $t('general.button.cancelOrder') " v-if=" !loading.cancelOrder "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </b-button>
        </div>
      </div>
    </div>



    <b-modal :title=" $t('dashboard.order.cancelOrder') " content-class="card card-ecart form-rounded-inputs"  header-class="card-header p-3 "
      v-if="isComponent" :visible="modalCancelOrder" @hidden="resetCancelOrder( )" cancel-variant="danger" size="md"
      hide-header-close>
      <v-observer tag="form" class="mb-4 animated fadeIn" ref="formReasonToCancel" @submit.prevent="fnValidateData()">

        <v-validation rules="required" v-slot="{ errors }" :name="'Reason'" v-show=" !cancelReason.message ">
          <table class="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th v-text="$t('dashboard.order.reason')"> </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" (reason,index) in reasons " :key="index">
                <td>
                  <input type="radio" :id=" index +'reason'" :value="reason.value" v-model="cancelReason.reason">
                </td>
                <td>
                  <label :for="index +'reason'" class="cursor-pointer m-0 w-100">
                    <span v-text=" reason.name "></span>
                    <br>
                    <span class="text-muted" v-text=" reason.description "> </span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div class=" p-2 bg-danger " v-if="errors.length > 0">
            {{ errors[0] }}
          </div>
        </v-validation>
        <v-validation rules="required|min:8|max:500" v-slot="{ errors }" :name=" $t('general.form.comments') "
          v-show=" !cancelReason.message ">
          <div class="form-group h-100">
            <label for="" v-text=" $t('general.form.comments') "> </label>
            <textarea cols="30" rows="5" maxlength="500" class="form-control " v-model="cancelReason.comments"
              :class=" { 'is-invalid':errors.length > 0 } "></textarea>
            <div class="invalid-feedback" v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </div>
        </v-validation>

        <div class="card m-0 border border-danger " v-if="cancelReason.message">
          <div class="card-body">
            <p><strong v-text="$t('dashboard.order.cancelSection.title')"> </strong></p>
            <p v-text="$t('dashboard.order.cancelSection.message1')"> </p>
            <p>
              <span v-text="$t('dashboard.order.cancelSection.message2')" class="mr-1"></span>
              <strong class="text-danger"
                v-text="fnFormatPrice( (orderInformation.totals.total * .10 ) , orderInformation.currency )">
              </strong>.
            </p>
            <v-validation :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
              :name="$t('general.button.confirmation')">
              <div class="form-group form-check m-0">
                <input type="checkbox" class="form-check-input" id="cancelReason" v-model=" cancelReason.charge ">
                <label class="form-check-label" for="cancelReason"
                  v-text="$t('dashboard.order.cancelSection.acceptmessage')">
                </label>
              </div>
              <p class="bg-danger pl-1" v-if="errors.length > 0">
                <small> {{ errors[0] }} </small>
              </p>
            </v-validation>
            <p>
              <small>
                <span v-text="$t('dashboard.order.cancelSection.link')"></span>
                <router-link target="_blank" to="/terms-&-conditions" v-text=" $t('signup.terms') "> </router-link>
              </small>
            </p>
          </div>
        </div>
        <input type="submit" hidden>

      </v-observer>

      <template v-slot:modal-footer>
        <div class="d-flex jusify-content-end ">
          <b-button variant="none" class="mr-2" @click="resetCancelOrder() "
            v-text=" $t('general.button.close') ">
          </b-button>
          <b-button variant="danger" class="" @click="fnValidateData() " :disabled="loading.cancelOrder">
            <span v-text=" $t('general.button.cancelOrder') " v-if=" !loading.cancelOrder "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";
  export default {
    data() {
      return {
        reasons: this.$t('dashboard.order.cancelReasons'),
      }
    },
    computed: {
      ...mapState('order', ['modalCancelOrder', 'loading', 'cancelReason', 'orderInformation', 'isComponent']),
    },
    methods: {
      ...mapMutations('order', ['resetCancelOrder', 'setModalCancelOrder']),
      ...mapActions('order', ['fnApiCancelOrder']),

      async fnValidateData() {
        if (await this.$refs['formReasonToCancel'].validate()) {
          this.fnApiCancelOrder();
        }
      }
    }
  }
</script>

<style>

</style>