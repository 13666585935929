<template>
  <div>
    <div class="card" :class=" getBannerType() ">
      <div class="card-header">
        <h5 :class="getTextType()">
          <i class=" fa mr-1" :class=" 'fa-' + titleIcon " v-if="titleIcon"></i>
          <span v-html="title"> </span>
        </h5>
      </div>
      <div class="card-body">
        <div class="card-title">
          <div class="row">
            <div class="col">
              <slot name="message-secction">
                <p v-text="message"> </p>
              </slot> 
            </div>
          </div>
        </div>
        <p class="text-right mb-0">
          <button class="btn " :class="getBtnType()" v-if="actionLabel" @click=" $emit('action') " :disabled="loading">
            <div v-if=" !loading ">
              <i class=" fa mr-1" :class=" 'fa-' + actionIcon " v-if="actionIcon"></i>
              <span v-text="actionLabel"></span>
            </div>
            <div v-else>
              <i class="fa fa-spinner fa-spin "></i>
            </div>
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        required: true,
      },
      message: {
        type: String,
      },
      bannerType: {
        type: String,
        required: true,
      },
      actionLabel: {
        type: String,
      },
      actionIcon: {
        type: String,
      },
      titleIcon: {
        type: String,
      },
      bannerIcon: {
        type: String,
      },
    },
    methods: {
      getBannerType() {
        switch (this.bannerType) {
          case 'success':
            return 'border-success'
            break;
          case 'info':
            return 'border-info'
            break;
          case 'warning':
            return 'border-warning'
            break;
          case 'danger':
            return 'border-danger'
            break;
          case 'dark':
            return 'border-dark'
            break;
          default:
            return 'border-info'
            break;
        }
      },
      getTextType() {
        switch (this.bannerType) {
          case 'success':
            return 'text-success'
            break;
          case 'info':
            return 'text-info'
            break;
          case 'warning':
            return 'text-warning'
            break;
          case 'danger':
            return 'text-danger'
            break;
          case 'dark':
            return 'text-black'
            break;
          default:
            return 'text-info'
            break;
        }
      },
      getBtnType() {
        switch (this.bannerType) {
          case 'success':
            return 'btn-success'
            break;
          case 'info':
            return 'btn-info'
            break;
          case 'warning':
            return 'btn-warning'
            break;
          case 'danger':
            return 'btn-danger'
            break;
          case 'dark':
            return 'btn-primary'
            break;
          default:
            return 'text-info'
            break;
        }
      },
    }
  }
</script>

<style>

</style>