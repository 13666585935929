<template>
  <div class="card shadow-none ">
    <div class="card-header ">
      <h5 class=" f-w-600 text-warning text-center " v-text="$t('alertMessages.importantInformation')">
      </h5>
    </div>
    <div class="card-body">

      <p class="text-center pb-2">
        <i class=" fa fa-exclamation-triangle mr-1"> </i>
        <strong v-text="$t('alertMessages.orderInternationalShiping')"> </strong>
      </p>
      <p class="" v-text="$t('alertMessages.orderInternationalShipingReqs')"> </p>

      <p class="mb-1" v-text="$t('components.trackingNumber.documentsMessage')"></p>

      <ul class="mb-3">
        <li>
          <b v-text=" $t('components.trackingNumber.intShippingList.label.title') + ': '"></b>
          <span v-text="$t('components.trackingNumber.intShippingList.label.message')"></span>
          (<small class="text-lowercase text-muted" v-text="$t('components.trackingNumber.availableMessage')"></small>).
        </li>
        <li>
          <b v-text=" $t('components.trackingNumber.intShippingList.orderSummary.title') + ': '"></b>
          <span v-text="$t('components.trackingNumber.intShippingList.orderSummary.message')"></span>
          (<small class="text-lowercase text-muted" v-text="$t('components.trackingNumber.availableMessage')"></small>).
        </li>
        <li>
          <b v-text=" $t('components.trackingNumber.intShippingList.bol.title') + ': '"></b>
          <span v-text="$t('components.trackingNumber.intShippingList.bol.message')"></span>
          (<small class="text-lowercase text-muted" v-text="$t('components.trackingNumber.availableMessage')"></small>).
        </li>
      </ul>

      <p class="mb-1" v-text="$t('components.trackingNumber.optionalDocumentsMessage')"></p>
      <ul class="mb-3">
        <li>
          <b v-text="$t('components.trackingNumber.intShippingList.fda.title') + ': ' "></b>
          <span v-text="$t('components.trackingNumber.intShippingList.fda.message')"></span>
          (<a href="https://www.fda.gov/" v-text="$t('general.button.moreInfo')" class="text-lowercase"
            target="_blank"></a>).
        </li>
        <li>
          <b v-text="$t('components.trackingNumber.intShippingList.tsca.title') + ': ' "></b>
          <span v-text="$t('components.trackingNumber.intShippingList.tsca.message')"></span>
          (<a href="https://drive.google.com/file/d/1bDnuiN9DetP-dNPoSpgrCq9xz58c8Ggu/view?usp=sharing"
            v-text="$t('general.button.moreInfo')" class="text-lowercase" target="_blank"></a>).
        </li>
      </ul>

      <p class="text-muted ">
        <span v-text="$t('components.trackingNumber.prohibitedItems') + ' ' "> </span>
        (<a href="https://www.fedex.com/en-us/shipping/international-prohibited-items.html"
          v-text="$t('general.button.here')" class="text-lowercase" target="_blank"></a>).
      </p>

      <div class="row justify-content-end">
        <div class="col-auto">
          <button class="btn btn-success f-w-600 " @click="$emit( 'update-shiping')" v-text="$t('general.button.ready')">
          </button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  export default {
    data() {
      return {}
    }
  }
</script>

<style>

</style>