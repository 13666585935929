<template>
  <div class="row justify-content-end" v-if="orderInformation.shipment_status != 'delivered'">
    <div class="col-12 col-xl-6"
      v-if="orderInformation.order_status != 'cancelled' && !shippingInformation.own_shipping ">
      <div class="card shadow-none ">
        <div class="card-header">
          <h6 v-text="$t('tables.pickup')"></h6>
        </div>
        <div class="card-body d-flex flex-column justify-content-center" v-if="pickupList.length == 0 ">
          <p class="py-3 text-center" v-if="pickupInformation.id == null">
            <router-link :to="'/dashboard/pickups?order_id=' + orderInformation.id "
              class="btn btn-success rounded-pill f-w-500" v-if="orderInformation.shipment_status == 'ready to ship'"
              v-text="$t('general.button.schedulePickup')">
            </router-link>
          </p>
        </div>

        <div class="card-body accordion" role="tablist" v-else>
          <div v-for=" (pickupInfo,index) in pickupList " :key="index" class="mb-2">

            <button v-b-toggle="'accordion'+index" class="w-100 btn btn-light mb-2 rounded-pill"
              v-text="$t('general.form.date') + ': ' + fnDate( pickupInfo.shipment.pickup.date,  CONST.DATE_FORMATS.DD_MM_YYYY )  "></button>

            <b-collapse :id="'accordion'+index" :visible=" index == 0 " accordion="my-accordion" role="tabpanel"
              class="card shadow-none ">
              <div class="card-body">
                 <p class="text-center">
                <strong class="f-w-500" v-text="$t('general.pickupNumber')"> </strong> <br>
                <span v-text="pickupInfo.pickup_number"> </span>
              </p>
              <hr>
              <div class="row">
                <div class="col-6">
                  <p>
                    <strong class="f-w-500" v-text="$t('general.form.date') + ': ' "> </strong>
                    <span v-text="fnDate( pickupInfo.shipment.pickup.date,  CONST.DATE_FORMATS.DD_MM_YYYY )"> </span>
                  </p>
                </div>
                <div class="col-6 text-right">
                  <p>
                    <strong class="f-w-500" v-text="$t('general.schedule') + ': ' "></strong>
                    <span v-text="pickupInfo.shipment.pickup.time_from + ':00 - ' "> </span>
                    <span v-text="pickupInfo.shipment.pickup.time_to + ':00' "> </span>
                  </p>
                </div>
                <div class="col-12">
                  <hr>
                  <p>
                    <strong class="f-w-500" v-text="$t('general.instructions') + ': ' "></strong> <br>
                    <span v-text="pickupInfo.shipment.pickup.instructions"></span>
                  </p>
                </div>
              </div>
              <hr>
              <p>
                <span v-text=" $t('tables.store') + ': ' + pickupInformation.origin.company "> </span> <br>
                <span v-text=" $t('general.form.email') + ': ' + pickupInformation.origin.email "> </span>
              </p>
              <p>
                <span v-text=" $t('tables.address') + ': ' "></span>
                <span v-text="fnFormatAddress( pickupInformation.origin.address )"></span>
              </p>
              </div>
             
            </b-collapse>

          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-6 ">
        <b-tabs class="card ecart-tabs ligth shadow-none " justified>
          <b-tab :title="$t('dashboard.order.shipping.destination')">
            <p class="m-0">
              <span
                v-text=" $t('tables.name') + ': ' + orderInformation.shipping_address.first_name + ' ' + orderInformation.shipping_address.last_name "></span>
            </p>
            <p class="m-0">
              <span v-text=" $t('tables.phone') + ': ' + orderInformation.shipping_address.phone "></span>
            </p>
            <p class="m-0 ">
              <span v-text=" $t('tables.address') + ': ' "></span>
              <span v-text="fnFormatAddress( orderInformation.shipping_address )"></span>
            </p>
          </b-tab>
          <b-tab :title="$t('dashboard.order.shipping.origin')" active>
             <p class="m-0">
            <span
              v-text=" $t('tables.name') + ': ' + shippingInformation.origin.first_name + ' ' + shippingInformation.origin.last_name "></span>
          </p>
          <p class="m-0">
            <span v-text=" $t('tables.phone') + ': ' + shippingInformation.origin.phone "></span>
          </p>
          <p class="m-0 ">
            <span v-text=" $t('tables.address') + ': ' "></span>
            <span v-text="fnFormatAddress( shippingInformation.origin )"></span>
          </p>
          </b-tab>
        </b-tabs>
    </div>
    <!-- <pre> {{ pickupList}} </pre> -->
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";
  export default {
    data() {
      return ({
        // showPickupOrigin: false,
      })
    },
    computed: {
      ...mapState('order', ['shippingInformation', 'orderInformation', 'pickupList']),
      pickupInformation() {
        if (this.pickupList.length > 0) {
          return this.pickupList[0];
        }
        return {
          id: null
        };
      },
    },
    methods: {
      // ...mapActions('order', ['']),
    }
  }
</script>


<style>
</style>